<template>
  <div class="background-outer">
    <main>
      <Header :showButtons="false" />
      <div class="background-inner">
        <h2>Privacy Policy</h2>
        <p>As of May 10, 2022</p>
        <div class="scrolling">
          <section class="content-section">
            <label>1. SUBJECT MATTER AND SCOPE</label>
            <p>
              We take the protection of your personal data very seriously. With
              this Privacy Policy, we would like to inform you about which
              personal data we collect and how and for what purposes it is
              processed. This Privacy Policy applies to visits to our website
              and when you contact us. We always treat your personal data in
              accordance with the statutory data protection regulations and this
              Privacy Policy.
            </p>
          </section>
          <section class="content-section">
            <label>2. CONTROLLER AND DATA PROTECTION OFFICER</label>
            <p>
              The Controller is Team Internet AG, Liebherrstraße 22, 80538
              Munich, Germany, Tel.: +49 89 416146010, E-Mail:
              info@teaminternet.com. If you have any questions about data
              protection, you can contact our data protection officer Christian
              Schmoll (christian.schmoll(AT)teaminternet.com).
            </p>
          </section>
          <section class="content-section">
            <label>3. VISITING OUR WEBSITE</label>
            <p>
              Each time you visit our website, our system automatically collects
              data and information from the computer system of the calling
              computer. In order for the pages to be displayed in your browser,
              the IP address of the terminal device you are using must be
              processed. In addition, there is further information about the
              browser of your end device. Ensuring the confidentiality and
              integrity of the personal data processed with our IT systems is of
              great importance to us. The data is also used to correct errors on
              the websites. For these purposes, the following data is logged:
            </p>
            <ul>
              <li>IP address of the calling computer</li>
              <li>Operating system of the calling computer</li>
              <li>Browser version of the calling computer</li>
              <li>Name of the retrieved file</li>
              <li>Date and time of the retrieval</li>
              <li>Amount of data transferred</li>
              <li>Referring URL</li>
            </ul>
            <p>
              This data is regularly deleted automatically after a few days. Our
              websites are hosted by data processors on the basis of a data
              processing agreement pursuant to Art. 28 GDPR. The legal basis for
              this data processing is our legitimate interest pursuant to Art. 6
              (1) lit. f) GDPR. Our legitimate interest is the operation of this
              website and the implementation of the protection goals of
              confidentiality, integrity and availability of the data.
            </p>
          </section>
          <section class="content-section">
            <label>4. CONTACTING US</label>
            <p>
              If you contact us, the information you provide will be stored for
              the purpose of processing the request. We need the information
              requested in a contact form on the website to process your
              enquiry, to address you correctly and to send you a reply. The
              legal basis for this data processing is our legitimate interest
              pursuant to Art. 6 (1) lit. f) GDPR. Our legitimate interest is to
              communicate with interested parties, visitors and customers. If
              the contact or communication is aimed at the conclusion of a
              contract or takes place within the context of an existing
              contractual relationship, the legal basis for the processing is
              Art. 6 (1) lit. b) GDPR. Enquiries and orders are stored in our
              CRM system. The CRM system is regularly checked to see whether
              data can be deleted. If data is no longer required in the context
              of a customer or interested party relationship or if a conflicting
              interest of the customer outweighs this, we will delete the data
              in question, provided that there are no statutory retention
              obligations to the contrary. The legal basis for this storage and
              processing is our legitimate interest pursuant to Art. 6 (1) lit.
              f) GDPR. Our legitimate interest is the marketing of our services.
            </p>
          </section>
          <section class="content-section">
            <label>5. AGE RESTRICTION</label>
            <p>
              This website is not intended or designed for use by children under
              the age of 16. We do not knowingly collect personal information
              from or about anyone under the age of 16.
            </p>
          </section>
          <section class="content-section">
            <label>6. RECIPIENTS OF DATA</label>
            <p>
              Within our company, those internal departments or organizational
              units receive your data that need them to fulfill their tasks, if
              necessary to perform and fulfill contracts with you, for data
              processing with your consent or to protect our legitimate
              interests. Data will only be passed on to third parties in
              accordance with the statutory provisions. We only disclose your
              data to third parties if this is necessary, for example, on the
              basis of Art. 6 (1) lit. b) GDPR for contractual purposes or to
              protect our legitimate interest in accordance with Art. 6 (1) lit.
              f) GDPR in the effective performance of our business operations.
              Your personal data may be disclosed by us to the following
              categories of recipients:
            </p>
            <ul>
              <li>
                We may disclose personal data to companies that are affiliated
                with us (e.g., companies under whose control we are, that are
                controlled by us, or with which we are under common control).
              </li>
              <li>
                We may share your personal information with companies that
                provide services to us, such as being used in the performance of
                contracts, sending mail and emails, analyzing customer data,
                providing marketing support, investigating fraudulent activity,
                conducting customer surveys, and providing customer service.
              </li>
              <li>
                We may allow third party vendors to use technology to collect
                information about your use of our website so that they can serve
                advertisements for products and services tailored to your
                interests. These advertisements may appear either on our website
                or on other websites.
              </li>
              <li>
                We may share your personal information if we sell all or part of
                our business, sell all or part of our business assets, or are
                otherwise involved in a merger or acquisition.
              </li>
              <li>
                We may disclose your personal information to other third parties
                to comply with a legal obligation; to protect the legal rights
                of our company, our employees, our agents, our customers, and
                our affiliates (or otherwise participate in any legal process
                relating to them), to protect the safety of our visitors, or to
                protect against fraud; or with your consent.
              </li>
            </ul>
            <p>
              Where we use service providers or third parties in the course of
              providing the Website and/or our services, we take appropriate
              legal precautions and technical and organizational measures to
              ensure the protection of your personal data. If we use content or
              tools from service providers or third-party providers as part of
              the provision of the website and/or the provision of our services
              and their registered office is located in a third country, data is
              regularly transferred to a third country. Third countries are
              countries in which the GDPR is not directly applicable law, i.e.
              countries outside the EU or the European Economic Area (EEA). The
              transfer of data to third countries only takes place if either an
              adequate level of data protection, consent or other legal
              permission, in particular appropriate safeguards pursuant to Art.
              46 GDPR, is available.
            </p>
          </section>
          <section class="content-section">
            <label>7. YOUR RIGHTS</label>
            <p>
              You have the right to free information about your stored personal
              data, its origin and recipient and the purpose of the data
              processing and a right to correction, blocking or deletion of this
              data. You also have the right to restrict processing and to object
              to processing. You also have the right to have your data that we
              process automatically handed over to you or to a third party in a
              common, machine-readable format. To exercise your rights, please
              contact us using the contact details above. You also have the
              right to lodge a complaint with the competent data protection
              supervisory authority.
            </p>
          </section>
          <section class="content-section">
            <label>8. WITHDRAWAL OF CONSENT</label>
            <p>
              Some data processing operations are only possible with your
              express consent. You can withdraw your consent at any time. To do
              so, it is sufficient to send us an informal message by e-mail
              using the contact details provided above. The legality of the data
              processing carried out until the withdrawal remains unaffected by
              the withdrawal.
            </p>
          </section>
          <section class="content-section">
            <label>9. RIGHT TO OBJECT</label>
            <p>
              INSOFAR AS YOUR DATA IS PROCESSED TO PROTECT OUR LEGITIMATE
              INTERESTS, AS EXPLAINED IN THIS PRIVACY POLICY, YOU MAY OBJECT TO
              THIS PROCESSING WITH EFFECT FOR THE FUTURE. TO DO SO, PLEASE
              CONTACT US USING THE CONTACT DETAILS PROVIDED ABOVE. IN PRINCIPLE,
              YOU ONLY HAVE THIS RIGHT TO OBJECT IF THERE ARE GROUNDS ARISING
              FROM YOUR PARTICULAR SITUATION (ART. 21 (1) GDPR). AFTER
              EXERCISING YOUR RIGHT TO OBJECT, YOUR PERSONAL DATA WILL NO LONGER
              BE PROCESSED FOR THESE PURPOSES UNLESS WE CAN DEMONSTRATE
              COMPELLING LEGITIMATE GROUNDS FOR THE PROCESSING WHICH OVERRIDE
              YOUR INTERESTS, RIGHTS AND FREEDOMS, OR IF THE PROCESSING IS FOR
              THE PURPOSE OF ASSERTING, EXERCISING OR DEFENDING LEGAL CLAIMS. IF
              THE PROCESSING IS FOR THE PURPOSES OF DIRECT MARKETING, YOU MAY
              EXERCISE YOUR RIGHT TO OBJECT AT ANY TIME (ART. 21 (2) GDPR) AND
              YOUR PERSONAL DATA WILL THEN NO LONGER BE PROCESSED FOR THE
              PURPOSES OF DIRECT MARKETING, REGARDLESS OF THE GROUNDS FOR THE
              OBJECTION.
            </p>
          </section>
          <section class="content-section">
            <label>10. MANDATORY DATA</label>
            <p>
              The provision of personal data is not required by law or contract,
              nor are you obliged to provide personal data, however, the
              provision of personal information is necessary for the conclusion
              of a contract in that certain information is mandatory in order to
              conclude (and perform) a contract.
            </p>
          </section>
          <section class="content-section">
            <label>11. AUTOMATED DECISION MAKING</label>
            <p>We do not use automated decision-making including profiling.</p>
          </section>
          <section class="content-section">
            <label>12. RETENTION AND DELETION</label>
            <p>
              We adhere to the principles of data avoidance and data economy. We
              therefore only store your personal data for as long as is
              necessary to achieve the purposes stated here or as stipulated by
              the storage periods provided for by law. If the purpose of storage
              no longer applies or if a storage period provided for by law
              expires, the personal data will be routinely blocked or deleted in
              accordance with the statutory provisions.
            </p>
          </section>
          <section class="content-section">
            <label>13. DATA SECURITY</label>
            <p>
              We take organizational, contractual and technical security
              measures in accordance with the state of the art to ensure that
              the provisions of data protection laws are complied with and to
              protect the data we process against accidental or intentional
              manipulation, loss, destruction or against access by unauthorized
              persons. Our website uses SSL encryption for security reasons and
              to protect the transmission of confidential content, such as
              orders, enquiries or payment data that you send to us.
            </p>
          </section>
          <section class="content-section">
            <label>14. CHANGES TO THIS PRIVACY POLICY</label>
            <p>
              We reserve the right to amend this Privacy Policy from time to
              time so that it always complies with the current legal
              requirements or in order to implement changes to our services in
              the Privacy Policy, e.g.
            </p>
          </section>
        </div>
      </div>
    </main>
  </div>
</template>

<style scoped>
.background-inner {
  padding: 48px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
}

.scrolling {
  height: calc(100vh - 240px);
  overflow-y: scroll;
}

.content-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

label {
  margin-bottom: 8px;
}

.content-section p {
  margin: 0;
  text-align: justify;
  display: inline-block;
}

.content-section + .content-section {
  border-top: 2px solid rgba(0, 0, 0, 0.12);
  margin-top: 24px;
  padding-top: 24px;
}

ul {
  display: inline-block;
  text-align: left;
  font-size: 1.5em;
  padding-left: 16px;
  max-width: 75ch;
  text-align: justify;
}
</style>

<script>
import Header from "@/components/shared/Header";

export default {
  name: "PrivacyPolicy",
  data: () => ({}),
  components: { Header },
  methods: {},
};
</script>
